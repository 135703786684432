.toast {
  padding: 0;
  cursor: default;
  min-height: 0;

  :global {
    .Toastify__toast-body {
      padding: 0;
    }
  }
}

.closeOnClick {
  cursor: pointer;
}

.container {
  width: 640px;
}
