@import "../../config/includes";

.wrapper {
  padding: 0 160px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content {
  min-width: 600px;
  max-width: 800px;
}

.header {
  margin-bottom: 24px;
}

.title {
  @include fontSize24;
  font-weight: 600;
  padding-bottom: 12px;
}

.subtitle {
  @include fontSize15;
  padding-bottom: 40px;
}

.buttons {

}
