@import "config/includes";

body {
  background-color: $white;
}

.publicAppWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content {
  display: flex;
  overflow: auto;
  height: 100%;
}
