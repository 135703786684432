@import "../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

.border {
  border-top: solid 1px $grey300;
  padding-bottom: 24px;
}

.bottomBorder {
  border-bottom: solid 1px $grey300;
}

.topPadded {
  padding-top: 20px;
}

.stateIcon {
  color: $green700;
  font-size: 20px;
}

.empty {
  @include fontSize15;
}

.emptyInfoTitle {
  font-weight: 600;
  padding-bottom: 12px;
}

.emptyInfoText {
  color: $textColor;
}

.error {
  margin-bottom: 24px;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.authenticatedInfo {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
