.body {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
}

.wrapper {
  margin: auto;
}
