.wrapper {
  display: flex;
  flex-direction: column;
}


.info {
  max-width: 600px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  button {
    max-width: 300px;
  }
}
