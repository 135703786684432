@import "config/includes";

.links {
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li {
    float: left;
    margin-right: 48px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttons:after {
  content: '';
  display: block;
  clear: both;
}
