@import "../../config/includes";

.wrapper {
  padding: 0 160px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  min-width: 1000px;
}

.states {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonWrapper {
  padding-top: 32px;
  width: 400px;
  text-align: center;

  .info {
    padding-bottom: 20px;
  }
}

.feedback {
  padding-top: 32px;
}

.networkSection {
  padding-top: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px $grey300;
  margin-bottom: 32px;
  padding-bottom: 28px;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 12px;
}

.descriptions {
  display: flex;
}

.left {
  flex: 50%;
  padding-right: 80px;
}

.right {
  flex: 50%;
  align-self: flex-end;
  text-align: right;
}
