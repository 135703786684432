@import 'config/includes';

.header {
  display: block;
  background-color: $white;
  padding-bottom: 20px;
}

.logo {
  height: 24px;
  padding: 40px 40px;
}
